/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import classNames from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CheckSumData, CostumerData } from 'types';

import { debounce } from '@onoff/utils';

import C from 'helpers/constants/api';
import { useSiteMetadata } from 'helpers/hooks';

import bouyguesService from 'services/bouyguesService';

import Input from 'components/UI/NewInput'; // TODO: Replace when new design is implemented in onoff ui
import Button from 'components/UI/NewButton'; // TODO: Replace when new design is implemented in onoff ui
import InputLabel from 'components/UI/InputLabel';

import styles from './Form.module.scss';

const attachOfferSchema = (email: string): yup.AnyObjectSchema => yup.object().shape({
  confirmEmail: yup.string().oneOf([email], 'L\'adresse email ne correspond pas').required('Adresse email invalide'),
});

interface FormData {
    confirmEmail: string;
    keepNumber: string;
    checkbox: boolean;
}

interface FormProps {
  email: string;
  costumerData: CostumerData;
  checkSumData: CheckSumData;
}

const Form: React.FC<FormProps> = ({
  email,
  costumerData,
  checkSumData,
}) => {
  const { bouyguesApi } = useSiteMetadata();
  const recaptchaInstance = useRef<Reaptcha | null>(null);
  const [recaptchaFailure, setRecaptchaFailure] = useState(false);
  const {
    register, handleSubmit, errors, reset, watch, setError, getValues,
  } = useForm<FormData>({
    resolver: yupResolver(attachOfferSchema(email)),
  });

  const watchTOSCheckbox = watch('checkbox', false);

  const handleResetRecaptchaFlow = (): void => {
    if (recaptchaInstance && recaptchaInstance.current) {
      recaptchaInstance.current.reset();
      setRecaptchaFailure(true);

      setError('confirmEmail', {
        message: 'Une erreur est survenue, veuillez rafraîchir la page',
      });
    }
  };

  const handleAttachOffer = useRef(debounce(async (recaptchaToken: string) => {
    const confirmEmail = getValues('confirmEmail');
    const keepNumber = getValues('keepNumber') === 'true';
    reset();
    const { pcid, callbackUrl, checksum } = checkSumData;
    bouyguesService(bouyguesApi).attachOffer(email.trim(), confirmEmail.trim(), recaptchaToken, keepNumber, pcid, callbackUrl, checksum)
      .then((response) => {
        window.location.href = response.redirectUrl;
      }).catch(() => {
        handleResetRecaptchaFlow();
      });
  })).current;

  const handleRecaptchaFlow = (token: string): void => {
    handleAttachOffer(token);
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(() => {
        if (recaptchaInstance && recaptchaInstance.current) {
          recaptchaInstance.current.execute();
        }
      })}
    >
      <InputLabel>
        <InputLabel.Text>
          Email
        </InputLabel.Text>
        <Input
          name="email"
          disabled
          value={email}
        />
      </InputLabel>

      <span className={styles.goBack}>
        <a href="">
          utiliser un autre e-mail
        </a>
      </span>

      <InputLabel className="mb-4">
        <InputLabel.Text>
          Entrez à nouveau votre email pour poursuivre
        </InputLabel.Text>
        <Input
          name="confirmEmail"
          ref={register()}
          errorMessage={errors.confirmEmail?.message}
        />
      </InputLabel>

      {costumerData.previousPhoneNumber.length ? (
        <div className={styles.radios}>
          <p className={styles.text}>J’aimerais</p>

          <InputLabel className={styles.label}>
            <input
              className={styles.radio}
              type="radio"
              name="keepNumber"
              value="true"
              ref={register()}
            />
            {`récupérer mon précédent numéro + ${costumerData.previousPhoneNumber}`}
          </InputLabel>

          <InputLabel className={styles.label}>
            <input
              className={styles.radio}
              type="radio"
              name="keepNumber"
              value="false"
              defaultChecked
              ref={register()}
            />
            obtenir un nouveau numéro
          </InputLabel>
        </div>
      ) : ''}

      <InputLabel className={styles.terms}>
        <input
          name="checkbox"
          type="checkbox"
          className={styles.checkbox}
          ref={register()}
        />
        <InputLabel.Text className={styles.termText}>
          <span>
            J’accepte les
            <a
              href="https://www.onoff.app/conditions-generales-d-utilisation"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              conditions générales de service
            </a>
            et je reconnais que mon adresse email et mon numéro de téléphone onoff seront transmis à Bouygues Telecom à des fins d’assistance client.
          </span>
        </InputLabel.Text>
      </InputLabel>

      <Reaptcha
        ref={recaptchaInstance}
        className={classNames('g-recaptcha u-row', styles.recaptcha)}
        sitekey={C.G_RECAPTCHA_PUBLIC_KEY}
        size="invisible"
        onVerify={handleRecaptchaFlow}
        onExpire={handleResetRecaptchaFlow}
        onError={handleResetRecaptchaFlow}
      />

      <Button disabled={!watchTOSCheckbox || recaptchaInstance.current === null || recaptchaFailure} type="submit">
        {checkSumData.callbackUrl ? 'valider' : 'valider et télécharger l’App'}
      </Button>
    </form>
  );
};

export default Form;
