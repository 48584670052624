import {
  CheckEmailRequest,
  CheckEmailResponse,
  BouyguesService,
  VerifyCheckSumResponse,
  VerifyCheckSumRequest,
  AttachOfferResponse,
  AttachOfferRequest,
  RegisterResponse, RegisterRequest,
} from 'types';

import api from 'helpers/server/bouyguesApi';

const bouyguesService: BouyguesService = (apiPath) => ({
  verifyEmail: (email) => api.post<CheckEmailResponse, CheckEmailRequest>(`${apiPath}/check-email`, { email }),
  verifyCheckSum: (data) => api.post<VerifyCheckSumResponse, VerifyCheckSumRequest>(`${apiPath}/checksum-verify`, data),
  attachOffer: (email, emailConfirmation, recaptchaToken, restorePreviousPhoneNumber, pcid, callbackUrl, checksum) => api.post<AttachOfferResponse, AttachOfferRequest>(`${apiPath}/attach-offer`, {
    email,
    emailConfirmation,
    recaptchaToken,
    restorePreviousPhoneNumber,
    pcid,
    callbackUrl,
    checksum,
  }),
  register: (email, password, recaptchaToken, pcid, callbackUrl, checksum) => api.post<RegisterResponse, RegisterRequest>(`${apiPath}/register`, {
    email,
    password,
    recaptchaToken,
    pcid,
    callbackUrl,
    checksum,
  }),
});

export default bouyguesService;
