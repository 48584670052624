/* eslint-disable import/prefer-default-export */

export const getQueryStringParameterByName = (queryName: string): string => {
  const formattedQueryName = queryName.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${formattedQueryName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(window.location.href);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
