import React, { useRef } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { ApplicationError, CostumerData } from 'types';

import { debounce } from '@onoff/utils';

import C from 'helpers/constants/api';
import { useSiteMetadata } from 'helpers/hooks';

import bouyguesService from 'services/bouyguesService';

import Input from 'components/UI/NewInput'; // TODO: Replace when new design is implemented in onoff ui
import Button from 'components/UI/NewButton'; // TODO: Replace when new design is implemented in onoff ui
import InputLabel from 'components/UI/InputLabel';

import styles from './Form.module.scss';

const verifyEmailSchema = (): yup.AnyObjectSchema => yup.object().shape({
  email: yup.string().email('Adresse email invalide').required('Adresse email invalide'),
});

interface FormData {
    email: string;
}

interface FormProps {
  onSubmitSuccess: (data: CostumerData) => void;
  handleEmail: (email: string) => void;
  hasErrors: boolean;
}

const Form: React.FC<FormProps> = ({
  onSubmitSuccess,
  handleEmail,
  hasErrors,
}) => {
  const { bouyguesApi } = useSiteMetadata();
  const {
    register, errors, reset, setError, handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(verifyEmailSchema()),
  });

  const handleRequestError = (message: string): void => {
    setError('email', {
      message,
    });
  };

  const handleEmailVerification = useRef(debounce(async ({ email }) => {
    reset();
    bouyguesService(bouyguesApi).verifyEmail(email.trim())
      .then((response) => {
        handleEmail(email);
        onSubmitSuccess(response);
      }).catch((error: ApplicationError) => {
        switch (error.code) {
          case C.ERROR_CODES.EMAIL_CHECK_NOT_EXISTS:
            handleRequestError(error.message);
            break;

          case C.ERROR_CODES.B2B_USER_NOT_ALLOWED:
            handleRequestError("L'offre n'est pas disponible car vous possédez déjà un compte Onoff Business");
            break;

          case C.ERROR_CODES.USER_BLOCKED:
            handleRequestError('Désolé. Une adresse email permanente est requise');
            break;

          default:
            handleRequestError('Une erreur est survenue, veuillez rafraîchir la page');
            break;
        }
      });
  })).current;

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(handleEmailVerification)}
    >
      <InputLabel>
        <InputLabel.Text>
          Email
        </InputLabel.Text>
        <Input
          name="email"
          ref={register()}
          errorMessage={errors.email?.message}
        />
      </InputLabel>

      <Button
        className={styles.submit}
        type="submit"
        disabled={hasErrors}
      >
        Continuer
      </Button>

    </form>
  );
};

export default Form;
