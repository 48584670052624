/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';

import styles from './InputLabel.module.scss';

const InputLabelText: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className = '',
  children,
  ...props
}) => (
  <div className={classNames(styles.labelText, className)} {...props}>
    {children}
  </div>
);

export default InputLabelText;
