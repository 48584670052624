/* eslint-disable jsx-a11y/label-has-associated-control,react/jsx-props-no-spreading */

import classNames from 'classnames';
import React from 'react';

import InputLabelText from './InputLabelText';

import styles from './InputLabel.module.scss';

export type InputLabelAppearance = 'light' | 'dark'

interface InputLabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  appearance?: InputLabelAppearance;
}

interface InputLabelComponent extends React.FC<InputLabelProps> {
  Text: typeof InputLabelText;
}

const InputLabel: InputLabelComponent = ({ // TODO: MOVE to DESIGN SYSTEM
  appearance = 'light',
  className = '',
  children,
  ...props
}) => (
  <label className={classNames(styles.label, styles[appearance], className)} {...props}>
    {children}
  </label>
);

InputLabel.Text = InputLabelText;

export default InputLabel;
