/* eslint-disable no-shadow,jsx-a11y/anchor-is-valid,max-len */
import React, { useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import classNames from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CheckSumData } from 'types';

import { debounce } from '@onoff/utils';

import C from 'helpers/constants/api';
import { useSiteMetadata } from 'helpers/hooks';

import bouyguesService from 'services/bouyguesService';

import Input from 'components/UI/NewInput'; // TODO: Replace when new design is implemented in onoff ui
import Button from 'components/UI/NewButton'; // TODO: Replace when new design is implemented in onoff ui
import InputLabel from 'components/UI/InputLabel';

import styles from './Form.module.scss';

const passwordMessage = 'Le mot de passe doit contenir 1 majuscule, 1 minuscule, 1 chiffre et au minimum 6 caractères';

const registerSchema = (): yup.AnyObjectSchema => yup.object().shape({
  password: yup.string().matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(^[ -~]+$)$/, passwordMessage).required(passwordMessage),
});

interface FormData {
    password: string;
}

interface FormProps {
  checkSumData: CheckSumData;
  email: string;
}

const Form: React.FC<FormProps> = ({
  email,
  checkSumData,
}) => {
  const { bouyguesApi } = useSiteMetadata();
  const recaptchaInstance = useRef<Reaptcha | null>(null);
  const [recaptchaFailure, setRecaptchaFailure] = useState(false);
  const {
    register, handleSubmit, errors, reset, setError, getValues,
  } = useForm<FormData>({
    resolver: yupResolver(registerSchema()),
  });

  const [TOSAgreed, setTOSAgreed] = useState(false);

  const handleResetRecaptchaFlow = (): void => {
    if (recaptchaInstance && recaptchaInstance.current) {
      recaptchaInstance.current.reset();
      setRecaptchaFailure(true);

      setError('password', {
        message: 'Une erreur est survenue, veuillez rafraîchir la page',
      });
    }
  };

  const handleRegister = useRef(debounce(async (recaptchaToken: string) => {
    const password = getValues('password');
    reset();
    const { pcid, callbackUrl, checksum } = checkSumData;
    bouyguesService(bouyguesApi).register(email.trim(), password, recaptchaToken, pcid, callbackUrl, checksum)
      .then((response) => {
        window.location.href = response.redirectUrl;
      }).catch(() => {
        handleResetRecaptchaFlow();
      });
  })).current;

  const handleRecaptchaFlow = (token: string): void => {
    handleRegister(token);
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(() => {
        if (recaptchaInstance && recaptchaInstance.current) {
          recaptchaInstance.current.execute();
        }
      })}
    >
      <InputLabel>
        <InputLabel.Text>
          Email
        </InputLabel.Text>
        <Input
          name="email"
          disabled
          defaultValue={email}
        />
      </InputLabel>

      <span className={styles.goBack}>
        <a href="">
          utiliser un autre e-mail
        </a>
      </span>

      <InputLabel className="mb-3">
        <InputLabel.Text>
          Mot de Passe
        </InputLabel.Text>
        <Input
          type="password"
          name="password"
          ref={register()}
          errorMessage={errors.password?.message}
        />
      </InputLabel>

      <InputLabel className={styles.terms}>
        <input
          name="checkbox"
          type="checkbox"
          className={styles.checkbox}
          onChange={(event): void => setTOSAgreed(event.target.checked)}
        />
        <InputLabel.Text className={styles.termText}>
          <span>
            J’accepte les
            <a
              href="https://www.onoff.app/conditions-generales-d-utilisation"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              conditions générales de service
            </a>
            et je reconnais que mon adresse email et mon numéro de téléphone onoff seront transmis à Bouygues Telecom à des fins d’assistance client.
          </span>
        </InputLabel.Text>
      </InputLabel>

      <Reaptcha
        ref={recaptchaInstance}
        className={classNames('g-recaptcha u-row', styles.recaptcha)}
        sitekey={C.G_RECAPTCHA_PUBLIC_KEY}
        size="invisible"
        onVerify={handleRecaptchaFlow}
        onExpire={handleResetRecaptchaFlow}
        onError={handleResetRecaptchaFlow}
      />

      <Button
        type="submit"
        disabled={!TOSAgreed || recaptchaInstance.current === null || recaptchaFailure}
      >
        Continuer
      </Button>
    </form>
  );
};

export default Form;
