/* eslint-disable max-len */
import React from 'react';

import { CheckSumData } from 'types';
import styles from './Register.module.scss';
import Form from './Form';

interface RegisterProps {
    email: string;
    checkSumData: CheckSumData;
}

const Register: React.FC<RegisterProps> = ({
  email,
  checkSumData,
}) => (
  <div>
    <div className={styles.textContainer}>
      <div className={styles.title}>
        Voici les étapes à suivre pour obtenir votre&nbsp;
        <span>numéro :</span>
      </div>
      <ol className={styles.orderedList}>
        <li>Entrez le mot de passe Onoff de votre choix. Cet email et mot de passe vous seront ensuite utiles pour vous connecter à votre application. </li>
        <li>Cliquez sur le bouton pour valider la création de votre compte Onoff et télécharger l’App Onoff.</li>
        <li>Ouvrez l’app et connectez-vous avec vos identifiants Onoff. Vous pouvez immédiatement utiliser votre nouveau numéro Onoff !</li>
      </ol>
    </div>

    <Form
      email={email}
      checkSumData={checkSumData}
    />
  </div>
);

export default Register;
