/* eslint-disable max-len */
import React from 'react';

import { CheckSumData, CostumerData } from 'types';

import Form from './Form';

import styles from './AttachOffer.module.scss';

interface AttachOfferProps {
    email: string;
    costumerData: CostumerData;
    checkSumData: CheckSumData;
}

const AttachOffer: React.FC<AttachOfferProps> = ({
  email,
  costumerData,
  checkSumData,
}) => (
  <>
    <div className={styles.textContainer}>
      <div className={styles.title}>
        Voici les étapes à suivre pour obtenir votre&nbsp;
        <span className={styles.noWrap}>numéro :</span>
      </div>
      <div className={styles.text}>Ravi de vous revoir parmi nous :)</div>
      <div className={styles.text}>Indiquez-nous maintenant, si vous désirez récupérer votre précédent numéro ou en créer un nouveau.</div>
    </div>

    <Form
      email={email}
      costumerData={costumerData}
      checkSumData={checkSumData}
    />
  </>
);

export default AttachOffer;
