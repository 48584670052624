/* eslint-disable max-len,no-shadow */
import React, { useEffect, useState } from 'react';
import phone from 'images/bouygues/phone_@2x.png';

import { getQueryStringParameterByName } from 'helpers/utils/misc';
import { useSiteMetadata } from 'helpers/hooks';
import C from 'helpers/constants/api';

import bouyguesService from 'services/bouyguesService';

import { ApplicationError, CheckSumData, CostumerData } from 'types';

import EmailVerification from './EmailVerification';
import AttachOffer from './AttachOffer';
import Register from './Register';

import bouyguesLogo from '../../images/bouygues/bouygues_logo_@2x.png';
import onoffLogo from '../../images/bouygues/onoff-logo.svg';

import styles from './Bouygues.module.scss';

const Bouygues: React.FC = () => {
  const { bouyguesApi } = useSiteMetadata();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState('');

  const [checkSumData, setCheckSumData] = useState<CheckSumData>({
    pcid: '',
    callbackUrl: '',
    checksum: '',
  });
  const [costumerData, setCostumerData] = useState<CostumerData>({
    emailAlreadyUsed: false,
    previousPhoneNumber: '',
  });

  useEffect(() => {
    const data: CheckSumData = {
      pcid: getQueryStringParameterByName('pcid'),
      callbackUrl: getQueryStringParameterByName('urlcallback'),
      checksum: getQueryStringParameterByName('checksum'),
    };

    bouyguesService(bouyguesApi).verifyCheckSum(data)
      .then(() => {
        setCheckSumData(data);
      })
      .catch((error: ApplicationError) => {
        switch (error.code) {
          case C.ERROR_CODES.BYTEL_OFFER_USED:
            setErrors('Une erreur est survenue. Nous vous invitons à vous connecter à votre Espace Client Bouygues Telecom afin de vérifier si votre option est toujours souscrite et si vous ne bénéficiez pas déjà de votre 2nd numéro');
            break;

          default:
            setErrors('Une erreur est survenue, veuillez rafraîchir la page');
            break;
        }
      });
  }, [bouyguesApi]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.bouyguesPromoImg}>
          <img src={phone} alt="Big phone" className={styles.img} />
        </div>

        <div className={styles.content}>
          <div className={styles.logos}>
            <img src={bouyguesLogo} className={styles.logo} alt="bouygues logo" />
            <img src={onoffLogo} className={styles.logo} alt="onoff logo" />
          </div>

          {!email && (
            <EmailVerification
              handleVerification={(data): void => {
                setCostumerData({
                  ...costumerData,
                  ...data,
                });
              }}
              handleEmail={setEmail}
              hasErrors={!!errors}
            />
          )}

          {!!email && costumerData.emailAlreadyUsed && (
            <AttachOffer
              costumerData={costumerData}
              checkSumData={checkSumData}
              email={email}
            />
          )}

          {!!email && !costumerData.emailAlreadyUsed && (
            <Register
              checkSumData={checkSumData}
              email={email}
            />
          )}

          {!!errors && (
            <div className={styles.errorMessage}>
              {errors}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bouygues;
