/* eslint-disable max-len */
import React from 'react';

import { CostumerData } from 'types';

import Form from './Form';

import styles from './EmailVerification.module.scss';

interface EmailVerificationProps {
    handleVerification: (data: CostumerData) => void;
    handleEmail: (email: string) => void;
    hasErrors: boolean;
}

const EmailVerification: React.FC<EmailVerificationProps> = ({
  handleVerification,
  handleEmail,
  hasErrors,
}) => (
  <>
    <div className={styles.textContainer}>
      <div className={styles.stepWelcome}>
        <div className={styles.text}>Vous venez de souscrire à l’offre Bouygues telecom pour obtenir un second numéro grâce à l’application Onoff et nous vous en remercions.</div>
        <div className={styles.text}>Pour commencer, entrez tout d’abord votre adresse email dans le champs ci-dessous :</div>
      </div>
    </div>

    <Form
      onSubmitSuccess={handleVerification}
      handleEmail={handleEmail}
      hasErrors={hasErrors}
    />
  </>
);

export default EmailVerification;
