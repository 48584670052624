/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/ban-ts-ignore */
import classnames from 'classnames';
import React, { forwardRef } from 'react';

// @ts-ignore
import styles from './Input.module.scss';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>{
  errorMessage?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ // TODO: MOVE to DESIGN SYSTEM
  className = '',

  errorMessage = null,

  onChange = (): void => { /* onChange */ },
  onClick = (): void => { /* onClick */ },
  ...props
}, ref) => (
  <div className={classnames(styles.container, { [styles.hasErrorMessage]: !!errorMessage })}>
    <input
      className={classnames(styles.input, className)}
      onChange={(e): void => onChange(e)}
      onClick={(e): void => onClick(e)}
      aria-invalid={!!errorMessage}
      data-testid="input"
      ref={ref}
      {...props}
    />

    {errorMessage && (
      <div className={styles.errorMessage}>
        {errorMessage}
      </div>
    )}
  </div>
));

export default Input;
