import React from 'react';

import Index, { IndexProps } from 'components/Layout';

import SEO from 'components/seo';
import Bouygues from 'components/Bouygues';
import CookieNotice from 'components/Banners/CookieNotice';

const BouyguesPage: React.FC<IndexProps> = ({ pageContext }) => (
  <>
    <Index pageContext={pageContext}>
      <main>
        <SEO pageTitleTermPath="bytel" />
        <Bouygues />
      </main>
      <CookieNotice />
    </Index>
  </>
);

export default BouyguesPage;
